.reports-cards{
    height: 210px;
}




.reports-tables{
    height: 360px;
    overflow: scroll;
    overflow-x: hidden;
}
.layout{
    background: #FAFAFA;
    transition: all 0.5s ease-in-out;

}
.layout-sidebar{
    transition: all 0.5s ease-in-out;
}
.layout-sidebar.toggled{
    width: 0;
    transform: translateX(-100%);
    opacity: 0;
}
.outlet-zone{
    transition: all 0.5s ease-in-out;
    flex-grow: 2;
    position: relative;
}
.sidebar-toggler{
    color: #8B909A;
    font-weight: 700;
    font-size: 32px;
    line-height: 24px;
    transition: all 0.25s ease-in;
    position: absolute;
    left: 10px;
    z-index: 3;
    top: 18px;
}
.sideBar-header{
    position: relative;
}
.sidebar-toggler .open{
    transform: rotate(0deg);
}
.sidebar-toggler .close{
    transform: rotate(-180deg);
    color: #ff8f0f;
}
.sidebar-toggler:hover{
    color: #ff8f0f;
    cursor: pointer;
}


@media (max-width: 575.98px) { 
    .layout-sidebar.toggled{
        transition: all 0.5s ease-in-out;
        overflow: hidden;
        transform: translateX(0%);
        opacity: 1;
        width: auto;

    }
    .layout-sidebar{
        width: 0;
        transform: translateX(-100%);
        opacity: 0;
    }
 }


.login{
    width: 100vw;
    height: 100vh;
    padding: 25px;
}
.left{
    padding: 25px;
}
.login-head{
    color:var(--rose-black);
    font-size:2rem;
}
.login-p{
    color:var(--paynes-gray);
    font-size:0.75rem;

}
.continer-left-login{
    width: 100%;
    border-radius: 15px;
    height: 100%;
    background: rgba(230, 177, 79, 0.797)
}


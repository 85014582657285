@import "rsuite/dist/rsuite.css";
.order-types-filters-bar{
    margin-top: 24px;
}
.order-types-filters-bar-item{
    color: #8B909A;
/* Light/Basic Typography/Paragraph */
font-family: Public Sans;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 22px;

border-bottom:2px solid #bbc2cf ;
padding: 5px 10px0;
}
.order-types-filters-bar-item:hover,
.order-types-filters-bar-item.active{
    color: #0F60FF;
    border-bottom:2px solid #0F60FF ;
    cursor: pointer;
}
.search-bar-container   {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 3px 1px rgba(53, 53, 53, 0.1);
    position: relative;
}
.search-input,.search-input::placeholder{
    border:none;
    color: #8B909A;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    padding: 8px 30px 8px 4px;
}
.icon{
    font-size: 18px;
    line-height: 37px;
    position: absolute;
    right:8px;
}
.order-filter-other{
    margin-top:28px ;
}
.datepicker{
    border:0;

}
.rs-lol3{
    left: calc(100% - 400px)!important;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 3px 1px rgba(53, 53, 53, 0.1);

}
.order-filter-other{
    padding: 0 18px;
}
.order-table{
    margin-top:28px ;

}
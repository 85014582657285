.sidebar{
background: #FFF;

padding: 20px 14px 5px 18px;
overflow-y: hidden;

}
.side-bar-lists{
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.side-bar-lists::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.sidebar-brand {
    color: #23272E;
    font-family: Public Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    }

.sidebar-brand .logo{
   color: #ff8f0f;
}

.sidebar-toggler{
    max-width: 32px;
    color: #8B909A;
    font-weight: 700;
    font-size: 32px;
    line-height: 24px;
    transition: all 0.25s ease-in;
    position: absolute;
    right: -28px;
    z-index: 3;
}
.sideBar-header{
    padding-left: 9px;
}
.sidebar-toggler .open{
    transform: rotate(-180deg);
}
.sidebar-toggler .close{
    transform: rotate(0deg);
    color: #ff8f0f;
}
.sidebar-toggler:hover{
    color: #ff8f0f;
    cursor: pointer;
}

.sidebar-list{
    padding: 10px 4px 0 5px;
    margin:0 0 5px 0 ;
}

.sidebar-list-header{
color: #8B909A;
padding:0 0 0 4px ;
/* Light/Basic Typography/Caption */
font-family: Public Sans;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 14px;
text-transform: uppercase;
}
.sidebar-list-item{
padding: 5px 4px;

border-radius: 6px;
color: #8B909A;
/* Light/Basic Typography/Paragraph */
font-family: Public Sans;
font-size: 13px;
font-style: normal;
transition: all 0.25s ease-in-out;
font-weight: 400;
margin: 8px 0;

}
.sidebar-list-item-link{
    color: inherit;
    text-decoration: none;
}
.sidebar-list-item-link.active ,.sidebar-list-item:hover,.sidebar-list-item:active{
    background: #F3F4F8;
    color: #23272E;
}
.sidebar-list-item-title{
    line-height: 15px;
}
.sidebar-list-item-icon{
    font-size: 22px;
    margin-right: 8px;
}
.sidebar-list-item.disabled .sidebar-list-item-link ,.sidebar-list-item.disabled:hover .sidebar-list-item-link,.sidebar-list-item.disabled:active .sidebar-list-item-link{
    cursor: not-allowed;
    text-decoration: none;
    background: #d8d3d300;
    color: #2d2f3586;
    filter: blur(1px);
}

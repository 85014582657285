.utable{
    background-color: #fefefe;
    --bs-table-border-color: #f3f3f3;
}
.utable-head .utable-head-th{
    height: 40px;
    background-color: #fff;
}
.invoice-window{
    position: absolute;
    z-index: -99999;
    opacity: 0;
    width: 7;
 
}
.custom-nav {
    display: flex;
    height: 62px;
    padding: 12px 0px;
    background: var(--bottom-background);

}
.custom-nav .navbar-brand{
    color: #23272E;
    font-family: Public Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
.custom-nav .navbar-nav{
    line-height: 22px;
    font-size: 24px;
}
.user{
width: 38px;
height: 38px;
background:#7367F0 ;
border-radius: 100px;
cursor: pointer;
}
.notification{
width: 38px;
height: 38px;
background:#fdfdfd ;
border-radius: 100px;
text-align: center;
line-height: 35px;
font-size: 26px;
transition: all 0.25s ease-in-out;
}
.notification:hover{
width: 38px;
height: 38px;
cursor: pointer;
background:#f3f3f3 ;
border-radius: 100px;
text-align: center;
line-height: 35px;
font-size: 26px;
}
.user{
    position: relative;
} 
.user .live{
    position: absolute;
    bottom: -3px;
    right: -3px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    line-height: 8px;
    content: " ";
    line-height: 38px;
    background: var(--light-solid-color-success-success-500-base, #28C76F);
    border:3px solid var(--light-solid-color-extra-card-background, #FFF);
}
.dropdown-menu.custom-drop{
    position: absolute;
    top: 50px;
    left:-85px;

}
.looder{
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    background: #f8f8ff;
}
.looder .header{
    font-size:3.5rem ;
    font-weight: 300;
    color: #e3590a;
}
.looder .parg{
    font-size:1.5rem ;
    font-weight: 300;
    color: #b0b0b0;
}
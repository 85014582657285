.all-users{
    height: 100vh;
}
.screen-all{
    padding: 15px;
}
.container-screen{
    height: 15vh;
    border-radius: 15px;
}
.table-users th{
    font-size: 0.85rem ;
    text-transform: capitalize;
    font-weight: 600;
    color: var(--prussian-blue);
}
.table-users tbody td{
font-size: 0.9rem;

}
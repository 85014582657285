.recite{
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 825px;
    min-height: 542px;
    background: rgb(255, 255, 255);
    border: 3px solid #000422;
}
.recite-header{
    background: #ffffff;
}
.head-brand{
color: #141414;
text-align: right;
font-family: Reem Kufi;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 2px;
}
.recite-brand{
color: #131313;
text-align: right;
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 3px;
}
.order{
    text-align: right;
}
.order-qr{
    height: 90px;
    margin: 0 0 0 auto;
    width: 90px;
    max-width: 90px;
    max-height: 90px;
}
.table.table-cutome{
    --bs-table-bg :#ffffff00
}
.information .customer .name{
color: #000000;
font-size: 16px;
text-transform: capitalize;
line-height: normal;

}
.information .customer .name .value,.InvoiceId{
color: #423f3f;
font-size: 18px;
text-transform: capitalize;
}
.tab-head{
    font-size: 0.85rem;
    text-transform: uppercase;
}
.body-values{
    font-size: 0.75rem;
}
.recite-footer{
    height: 100%;
    font-size: 1rem;
    color: #4d2701;
    font-weight: 200;

}
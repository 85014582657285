:root{
  --black: #000000ff;
--white: #FFFFFFff;
--prussian-blue: #1F344Cff;
--slate-gray: #647385ff;
--paynes-gray: #4E5D6Fff;
--rose-taupe: #865F5Dff;
--rose-light: rgb(228, 201, 200);
--rose-black: rgb(111, 76, 75);

--bottom-background: #FAFAFA;
}

.App-link {
  color: #61dafb;
}

body{
  height: 200vh;
}